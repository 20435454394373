<template>
  <div>
    <main class="container py-5">
      <div class="row pt-5">
        <div class="col-md-12" style="text-align: left">
          <h1 style="text-decoration: line-through">
            CRÉDIT REFUSÉ POUR UN BIEN&thinsp;?
          </h1>
          <p class="h1" style="font-weight: normal">
            La banque estime votre société trop jeune, vous n’avez pas assez de
            garanties, vous avez subi précédemment une faillite ? ou
            <b>vous êtes fiché bancaire</b>, nous avons la solution pour vous.
          </p>
          <div class="d-flex py-5">
            <!-- Bouton "email" à droite -->
            <div>
              <a
                href="mailto:info@izzysolution-immo.be"
                class="btn btn-lg btn-primary"
                >Email</a
              >
            </div>
            <div class="px-5">
              <a class="btn-tel text-dark" href="tel:0032478124140"
                >Tel. +32 478 12 41 40</a
              >
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div class="bg-primary pb-5 mt-200">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: left">
          <img
            class="mt-200-neg"
            src="../assets/izzy-solution_immo_1.jpg"
            style="border-radius: 15px; width: 100%"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4"></div>
        <div class="col-md-8" style="text-align: left">
          <p>
            <b>Votre propre immeuble en location-vente</b><br />
            1. Votre financement commence avec le paiement anticipé. <br />
            2. Vous remboursez la propriété mensuellement.<br />
            3. Vous payez la « valeur résiduelle » et devenez propriétaire à 100
            % du bien.
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <p
            class="h1 py-4"
            style="
              text-align: left;
              border-top: 1px solid #000;
              border-bottom: 1px solid #000;
            "
          >
            Votre propre bâtiment dès le premier jour !<br />
            <span style="font-size: 23px; font-weight: normal"
              >Acompte, remboursement mensuel, valeur résiduelle... C'est tout
              !</span
            >
          </p>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12" style="text-align: left">
          <p class="h1 py-4">Des biens que nous finançons pour vous</p>
        </div>
      </div>
      <div class="row mb-5 a-propos-row">
        <div class="col-md-6" style="text-align: left">
          <p class="mb-1">
            Bâtiments résidentiels (maisons, appartements) <br />
            • Bâtiments commerciaux <br />
            • Restauration <br />
            • Bureaux <br />
            • Entrepôts <br />
            • Hangars <br />
            • Terrains (terrains à bâtir, terrains agricoles)
          </p>
        </div>
        <div class="col-md-6" style="text-align: left">
          <p class="mb-1">
            Nous finançons uniquement des biens immobiliers situés en Belgique.
            Nous finançons les biens immobiliers que vous nous apportez
            vous-même ainsi que les biens immobiliers de notre propre
            portefeuille.
          </p>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-md-4">
          <img
            class="my-1"
            style="width: 90%; margin: auto"
            src="../assets/immo-1.png"
          />
        </div>
        <div class="col-md-4">
          <img
            class="my-1"
            style="width: 90%; margin: auto"
            src="../assets/immo-2.png"
          />
        </div>
        <div class="col-md-4">
          <img
            class="my-1"
            style="width: 90%; margin: auto"
            src="../assets/immo-3.png"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12" style="text-align: left">
          <p class="h1 py-4">Qui est éligible&thinsp;?</p>
        </div>
      </div>
      <div class="row mb-5 a-propos-row">
        <p class="mb-1" style="text-align: left">
          Avez-vous un numéro de TVA ou d'entreprise (avec une activité
          principale ou secondaire) ? Ou avez-vous une entreprise en formation ?
          Vous êtes alors éligible à un financement de notre part.
        </p>
      </div>
    </div>
  </div>
  <div class="pb-5 mt-200">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: left">
          <p class="h1">
            Comment fonctionne la location-vente pour les entrepreneurs&thinsp;?
          </p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-4 d-flex">
          <div class="flex-fill p-3 rounded" style="background-color: #fcdc30">
            <!-- Contenu de la première colonne -->
            <span class="round-num mt-4" style="padding-right: 3px">1</span>
            <p class="mx-3 my-4" style="text-align: left">
              Votre financement « location-vente pour entrepreneurs » commence
              par le versement de l'acompte. Nous vous demandons de déposer 20 à
              25% du prix d'achat du bien (évalué par un bureau d'expertise)
              chez notre notaire. Vous ne disposez pas de ressources suffisantes
              ? Mais êtes-vous déjà propriétaire d’un immeuble et/ou d’un
              véhicule ? Veuillez nous contacter pour discuter des possibilités
              à cet égard.
            </p>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="flex-fill p-3 rounded" style="background-color: #fcdc30">
            <!-- Contenu de la deuxième colonne -->
            <span class="round-num mt-4">2</span>
            <p class="mx-3 my-4" style="text-align: left">
              Après le paiement anticipé, vous payez une redevance mensuelle
              fixe qui est déterminée en fonction d'un certain nombre de
              paramètres (prix d'achat, paiement anticipé, durée et valeur
              résiduelle). Concernant la durée de location, nous vous proposons
              différentes options.
            </p>
          </div>
        </div>
        <div class="col-md-4 d-flex">
          <div class="flex-fill p-3 rounded" style="background-color: #fcdc30">
            <!-- Contenu de la troisième colonne -->
            <span class="round-num mt-4">3</span>
            <p class="mx-3 my-4" style="text-align: left">
              Après un processus de remboursement mensuel réussi, la seule
              option qui reste est de devenir propriétaire légal de la
              propriété. Vous aurez alors la possibilité d’acheter ou de
              racheter la propriété à sa valeur résiduelle. Nous déterminons au
              préalable la valeur résiduelle avec vous. Dès lors, vous êtes
              propriétaire à 100% de votre bien. Toutes nos félicitations!
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12" style="text-align: left">
          <p class="h1 py-4">
            Avantages de la location-vente pour les entrepreneurs
          </p>
        </div>
      </div>
      <div class="row mb-5 a-propos-row" style="text-align: left">
        <p class="mb-1">
          • Chez Izzy Solution, nous parvenons rapidement à un accord. <br />
          • Vous recevez des conseils, un accompagnement et un suivi
          personnalisés. <br />
          • Les frais d'occupation mensuels sont une dépense (partiellement)
          déductible. <br />
          • Vous pouvez compter sur des montants fixes, non soumis à
          indexations. <br />
          • Vous bénéficiez d'une grande flexibilité dans la détermination des
          différents paramètres. <br />
          • Nous vous offrons une option d'achat transférable. <br />
          • Chez Izzy Solution vous choisissez une solution sans intérêt. <br />
          • Vous pouvez compter sur une intervention notariée dans chaque
          dossier.
        </p>
      </div>
    </div>
  </div>
  <div class="pb-5 mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="text-align: left">
          <p class="h1" style="font-weight: normal">
            <b>Demandez votre devis</b> et démarrez dès aujourd'hui, nous vous
            fournirons un devis dans les 24h&thinsp;!
          </p>
          <div class="d-flex py-5">
            <!-- Bouton "email" à droite -->
            <div>
              <a
                href="mailto:info@izzysolution-immo.be"
                class="btn btn-lg btn-primary"
                >Email</a
              >
            </div>
            <div class="px-5">
              <a class="btn-tel text-dark" href="tel:0032478124140"
                >Tel. +32 478 12 41 40</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-5 mt-5" style="text-align: left; font-size: 16px">
    <div class="row">
      <div class="col-md-6">© 2023 Izzy Solution</div>
      <div class="col-md-6" style="text-align: right">
        <a href="https://izzysolution-car.be" class="text-dark">
          Nous proposons aussi des solutions de financement pour des véhicules
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>

<style scoped>
/* Ajoute tes styles personnalisés ici */
</style>
